import {ALPHA_VANTAGE_KEY} from '../config/contants';
import axios from 'axios';

export function fetchBySymbol(symbol) {
  return axios.get(`https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${symbol}&apikey=${ALPHA_VANTAGE_KEY}`)
  .then(({status, data}) => {
    if (data.Note) {
      throw new Error(data.Note);
    }
    if (status === 200) {
      return {
        type: `ALPHA_VANTAGE_API_SUCCESS`,
        data
      }
    }

    throw new Error('Falha de comunicação com a API ALPHA_VANTAGE');
  })
  .catch(error => {
    return {
      type: `ALPHA_VANTAGE_API_ERROR`,
      error
    }
  });
}
