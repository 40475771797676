export const AntDesign = require('./AntDesign.json');
export const Entypo = require('./Entypo.json');
export const EvilIcons = require('./EvilIcons.json');
export const Feather = require('./Feather.json');
export const FontAwesome = require('./FontAwesome.json');
export const Foundation = require('./Foundation.json');
export const Ionicons = require('./Ionicons.json');
export const MaterialCommunityIcons = require('./MaterialCommunityIcons.json');
export const MaterialIcons = require('./MaterialIcons.json');
export const Octicons = require('./Octicons.json');
export const SimpleLineIcons = require('./SimpleLineIcons.json');
export const Zocial = require('./Zocial.json');