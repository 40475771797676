import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import '../style/component/company.screen.style.css';
import Service from '../component/service.component';
import NewService from '../component/new.service.component';

import {getCompanies, addCompany, deleteCompany, updateCompany} from '../action/selected.company.action';
import Loading from '../component/loading.component';
import {storage, storageRef} from '../config/firebase.config';
import firebase from 'firebase';

//TODO integration with Flow
class Company extends PureComponent {

  state = {
    newServiceLoading: false,
    companiesLoading: false,
  };

  componentDidMount() {
    this.setState({companiesLoading: true}, async () => {
      const {match: {params}, getCompanies} = this.props;
      await getCompanies(params.id);
      this.setState({companiesLoading: false});
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  _renderService = (item, index) => {
    const {user} = this.props;
    const isUserLogged = user !== null;
    return (
      <Service
        key={item.id}
        company={item}
        deleteCompany={this._deleteCompany}
        updateCompany={this._updateCompany}
        uploadFile={this._uploadFile}
        hasPermission={isUserLogged}
      />
    );
  };

  _deleteCompany = async (companyId, downloadURL) => {
    const {selectedCompany: {id}} = this.props;
    if (downloadURL && downloadURL !== ''){
      storage.refFromURL(downloadURL)
      .delete()
      .catch(() => console.error('Erro ao excluir imagem.'));
    }
    if (window.confirm(`Deseja excluir a empresa?`))
      await this.props.deleteCompany(id, companyId);
  };

  _addCompany = () => {
    const {selectedCompany: {id}, addCompany} = this.props;
    this.setState({newServiceLoading: true}, async () => {
      await addCompany(id);
      this.setState({newServiceLoading: false});
    });
  };

  _updateCompany = async (companyId, company) => {
    const {selectedCompany: {id}, updateCompany} = this.props;
    return await updateCompany(id, companyId, company);
  };

  _uploadFile = (companyId, file, metadata, downloadURL, successCallback, errorCallback) => {
    const {selectedCompany: {id}} = this.props;

    if (downloadURL && downloadURL !== ''){
      storage.refFromURL(downloadURL)
      .delete()
      .catch(errorCallback);
    }

    const uploadTask = storageRef.child(`images/${id}/${companyId}-${file.name}`).put(file, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      null,
      function(error) {
        switch (error.code) {
          case 'storage/unauthorized':
            alert('Não autorizado!');
            break;

          case 'storage/canceled':
            alert('Cancelado!');
            break;


          case 'storage/unknown':
          default:
            alert('Erro desconhecido.');
            break;
        }
        errorCallback();
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        successCallback(downloadURL);
      })
  };

  render() {
    const {selectedCompany: {list=[]}, user} = this.props;
    const {newServiceLoading, companiesLoading} = this.state;
    const isUserLogged = user !== null;

    if (companiesLoading)
      return (
        <div id='service-section' className="center-content flex-grow-1 relative">
          <Loading isLoading={companiesLoading} text='Carregando empresas...'/>
        </div>
      );

    return (
      <div id='service-section' className="flex flex-grow-1 row content-evenly flex-wrap center-content">
        {list.map((item, index) => this._renderService(item, index))}
        {isUserLogged &&
          <NewService add={this._addCompany} isLoading={newServiceLoading}/>
        }
      </div>
    );
  }
}

const mapStateToProps = ({user, selectedCompany}) => {
  return {
    user,
    selectedCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (id) => dispatch(getCompanies(id)),
    addCompany: (id) => dispatch(addCompany(id)),
    deleteCompany: (id, companyId) => dispatch(deleteCompany(id, companyId)),
    updateCompany: (id, companyId, company) => dispatch(updateCompany(id, companyId, company)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
