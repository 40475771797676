import {
  FETCH_OUR_SERVICES_THIRD,
  UPDATE_OUR_SERVICES_THIRD,
} from '../config/contants';

export default (state = {
  title: 'Consultoria em Investimento',
  texts: [
    'Operações day trade',
    'Operações swing trade',
    'Renda fixa',
    'Ações',
    'Fundos de Investimento',
  ],
  downloadURL: require('../resource/coin_poster_u6385.png'),
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_OUR_SERVICES_THIRD:
      return {
        ...state,
        ...data
      };
    case FETCH_OUR_SERVICES_THIRD:
      return data;
    default:
      return state;
  }
}