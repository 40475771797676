import {
  SET_COMPANY,
  ADD_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
} from '../config/contants';

export default (state = {id: null, list: []}, action) => {
  const {type, company, companies, companyId} = action;
  switch (type) {
    case SET_COMPANY:
      return companies;
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        list: [
          ...state.list,
          company
        ]
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== companyId),
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === company.id) {
            return company
          }

          return item;
        })
      };
    default:
      return state;
  }
}