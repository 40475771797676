import {
  FETCH_FOOTER_ICONS,
  UPDATE_FOOTER_ICONS,
} from '../config/contants';

export default (state = {
  facebook: true,
  instagram: true,
  linkedin: true,
  phone: true,
  whatsapp: true,
  facebook_text: '',
  instagram_text: '',
  linkedin_text: '',
  whatsapp_text: '',
  phone_text: '',
}, action) => {
  const {type, data} = action;
  switch (type) {
    case FETCH_FOOTER_ICONS:
      return {
        ...state,
        ...data
      };
    case UPDATE_FOOTER_ICONS:
      return data;
    default:
      return state;
  }
}
