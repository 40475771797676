import React, {PureComponent} from 'react';
import Icon from '../style/fonts/glyphmaps/icon.component';

//TODO integration with Flow
class FooterIcons extends PureComponent {

  constructor(props) {
    super(props);

    const {
      facebook,
      instagram,
      linkedin,
      whatsapp,
      phone,
      facebook_text,
      instagram_text,
      linkedin_text,
      whatsapp_text,
      phone_text,
    } = props;

    this.state = {
      isEdit: false,
      isEnableInput: false,
      showEditBtn: false,
      isLoading: false,
      inputText: '',
      iconType: '',
      facebook,
      instagram,
      linkedin,
      whatsapp,
      phone,
      facebook_text,
      instagram_text,
      linkedin_text,
      whatsapp_text,
      phone_text,
    }
  }

  componentDidMount() {
    this.props.fetchFooterIcons()
    .then(({data}) => this.setState({...data}));
  }

  _onMouseEnter = () => this.setState({showEditBtn: true});

  _onMouseLeave = () => this.setState({showEditBtn: false});

  _enableEdit = () => this.setState({isEdit: true});

  _cancel = () => {
    const {
      facebook,
      instagram,
      linkedin,
      whatsapp,
      phone,
      facebook_text,
      instagram_text,
      linkedin_text,
      whatsapp_text,
      phone_text,
    } = this.props;

    this.setState({
      isEdit: false,
      showEditBtn: false,
      isEnableInput: false,
      inputText: '',
      iconType: '',
      facebook,
      instagram,
      linkedin,
      whatsapp,
      phone,
      facebook_text,
      instagram_text,
      linkedin_text,
      whatsapp_text,
      phone_text,
    });
  };

  _toggleFacebook = () => this.setState({facebook: !this.state.facebook});
  _toggleInstagram = () => this.setState({instagram: !this.state.instagram});
  _toggleLinkedIn = () => this.setState({linkedin: !this.state.linkedin});
  _toggleWhatsApp = () => this.setState({whatsapp: !this.state.whatsapp});
  _togglePhone = () => this.setState({phone: !this.state.phone});

  _save = () => {
    this.setState({
      isEdit: false,
      showEditBtn: false,
      isLoading: true,
    }, () => {
      const {
        facebook,
        instagram,
        linkedin,
        whatsapp,
        phone,
        facebook_text,
        instagram_text,
        linkedin_text,
        whatsapp_text,
        phone_text,
      } = this.state;
      this.props.updateFooterIcons({
        facebook,
        instagram,
        linkedin,
        whatsapp,
        phone,
        facebook_text,
        instagram_text,
        linkedin_text,
        whatsapp_text,
        phone_text,
      })
      .then(_ => this.setState({isLoading: false, isEnableInput: false}))
      .catch(_ => this.setState({isLoading: false, isEnableInput: false}));
    });
  };

  _enableInput = (iconType, iconText) => this.setState({isEnableInput: true, inputText: iconText, iconType});

  _onChangeNewText = (event) => this.setState({inputText: event.target.value});

  _onKeyDownNewText = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let obj = {};
      obj[this.state.iconType] = this.state.inputText;
      console.warn({obj});
      this.setState({...obj}, this._save);
    }
  };

  _openLink = (link) => (!link && link === '') || window.open(link);

  _copyText = (text) => {
    const el = document.createElement('input');
    el.value = text;

    document.body.appendChild(el);
    el.focus();
    el.select();
    el.setSelectionRange(0, 99999); /*For mobile devices*/

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(el);
  };

  _openPhone = (text) => {
    if (!text && text === '') return;

    this._copyText(text);

    const linkEl = document.createElement('a');
    linkEl.href = `tel:+55${text}`;
    linkEl.target = '_self';
    document.body.appendChild(linkEl);
    linkEl.click();
    document.body.removeChild(linkEl);
  };

  _openWhatsApp = (text) => {
    if (!text && text === '') return;

    this._copyText(text);

    window.open(`https://wa.me/55${text}`);
  };

  render() {
    const {
      isEdit,
      showEditBtn,
      facebook,
      facebook_text,
      instagram,
      instagram_text,
      linkedin,
      linkedin_text,
      whatsapp,
      whatsapp_text,
      phone,
      phone_text,
      isLoading,
      isEnableInput,
      inputText
    } = this.state;
    const {hasPermission} = this.props;

    return (
      <div
        className='flex row flex-wrap align-start relative'
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
        style={{padding: 15}}
      >
        {!isEnableInput ?
          <>
            {(hasPermission || facebook) &&
            <Icon
              fontFamily='AntDesign'
              name='facebook-square'
              color={facebook ? '#FFF' : '#555'}
              size={30}
              style={{marginRight: 10}}
              onClick={!hasPermission ? () => this._openLink(facebook_text) : (isEdit ? this._toggleFacebook : () => this._enableInput('facebook_text', facebook_text))}
              className={'icon-btn'}
              title={facebook_text}
            />
            }
            {(hasPermission || instagram) &&
            <Icon
              fontFamily='AntDesign'
              name='instagram'
              color={instagram ? '#FFF' : '#555'}
              size={30}
              style={{marginRight: 10}}
              onClick={!hasPermission ? () => this._openLink(instagram_text) : (isEdit ? this._toggleInstagram : () => this._enableInput('instagram_text', instagram_text))}
              className={'icon-btn'}
              title={instagram_text}
            />
            }
            {(hasPermission || linkedin) &&
            <Icon
              fontFamily='AntDesign'
              name='linkedin-square'
              color={linkedin ? '#FFF' : '#555'}
              size={30}
              style={{marginRight: 10}}
              onClick={!hasPermission ? () => this._openLink(linkedin_text) : (isEdit ? this._toggleLinkedIn : () => this._enableInput('linkedin_text', linkedin_text))}
              className={'icon-btn'}
              title={linkedin_text}
            />
            }
            {(hasPermission || whatsapp) &&
            <Icon
              fontFamily='FontAwesome'
              name='whatsapp'
              color={whatsapp ? '#FFF' : '#555'}
              size={30}
              style={{marginRight: 10}}
              onClick={!hasPermission ? () => this._openWhatsApp(whatsapp_text) : (isEdit ? this._toggleWhatsApp : () => this._enableInput('whatsapp_text', whatsapp_text))}
              className={'icon-btn'}
              title={whatsapp_text}
            />
            }
            {(hasPermission || phone) &&
            <Icon
              fontFamily='Entypo'
              name='phone'
              color={phone ? '#FFF' : '#555'}
              size={30}
              onClick={!hasPermission ? () => this._openPhone(phone_text) : (isEdit ? this._togglePhone : () => this._enableInput('phone_text', phone_text))}
              className={'icon-btn'}
              title={phone_text}
            />
            }
          </>
          :
          <input
            autoFocus={true}
            className='font-16 weight-400 font-montserrat'
            style={{flex: 1, borderBottom: '1px solid black', backgroundColor: '#FFFFFF'}}
            value={inputText}
            onChange={this._onChangeNewText}
            onKeyDown={this._onKeyDownNewText}
          />
        }
        {isLoading ?
          <img src={require('../resource/loading.svg')} alt='carregando...' style={{width: 20, height: 20, borderRadius: 20, backgroundColor: '#FFF'}}/>
          :
          null
        }
        {hasPermission &&
          <span style={{position: 'absolute', top: -5, right: -5}}>
            {(isEdit || isEnableInput) &&
            <Icon
              onClick={this._cancel}
              fontFamily='AntDesign'
              name='close'
              color={'#FFF'}
              size={20}
              className='icon-btn'
            />
            }
            {(isEdit) &&
            <Icon
              onClick={this._save}
              fontFamily={'Ionicons'}
              name={'ios-save'}
              color={'#FFF'}
              size={20}
              className='icon-btn'
            />
            }
          </span>
        }
        {showEditBtn && !isEdit && !isLoading && hasPermission && !isEnableInput &&
          <Icon
            onClick={this._enableEdit}
            fontFamily={'FontAwesome'}
            name={'edit'}
            color={'#FFF'}
            size={20}
            className='icon-btn'
            style={{position: 'absolute', top: -5, right: -5}}
          />
        }
      </div>
    );
  }
}

export default FooterIcons;
