import React, {PureComponent} from 'react';
import '../style/component/our.services.component.style.css';
import OurServicesItem from '../component/our.services.item.component';
import EditableText from './editable.text.component';

class OurServices extends PureComponent {

  state = {
    isTitleLoading: false,
    isFirstServiceLoading: false,
    isSecondServiceLoading: false,
    isThirdServiceLoading: false,
  };

  _saveTitle = (title) => {
    this.setState({
        isTitleLoading: true,
      },
      () => {
        this.props.updateOurServices({title})
        .then(_ => this.setState({isTitleLoading: false}))
        .catch(err => this.setState({isTitleLoading: false}));
      }
    );
  };

  _saveFirstService = (data) => {
    this.setState({
        isFirstServiceLoading: true,
      },
      () => {
        this.props.updateFirstService(data)
        .then(_ => this.setState({isFirstServiceLoading: false}))
        .catch(err => this.setState({isFirstServiceLoading: false}));
      }
    );
  };

  _saveSecondService = (data) => {
    this.setState({
        isSecondServiceLoading: true,
      },
      () => {
        this.props.updateSecondService(data)
        .then(_ => this.setState({isSecondServiceLoading: false}))
        .catch(err => this.setState({isSecondServiceLoading: false}));
      }
    );
  };

  _saveThirdService = (data) => {
    this.setState({
        isThirdServiceLoading: true,
      },
      () => {
        this.props.updateThirdService(data)
        .then(_ => this.setState({isThirdServiceLoading: false}))
        .catch(err => this.setState({isThirdServiceLoading: false}));
      }
    );
  };

  render() {
    const {isUserLogged, title, ourServicesFirst, ourServicesSecond, ourServicesThird, ourServicesFirstImage} = this.props;
    const {isTitleLoading, isFirstServiceLoading, isSecondServiceLoading, isThirdServiceLoading} = this.state;

    return (
      <div id="our-services-section" className="flex column align-center">
        <div className='center-content'>
          <EditableText hasPermission={isUserLogged} isLoading={isTitleLoading} save={this._saveTitle} text={title} classNames={'font-30 weight-700 our-services-title'}/>
          <div className='flex row content-evenly flex-wrap'>
            <OurServicesItem
              hasPermission={isUserLogged}
              {...ourServicesFirst}
              saveService={this._saveFirstService}
              isLoading={isFirstServiceLoading}
              imgId='first'
              uploadImage={ourServicesFirstImage}
            />
            <OurServicesItem
              hasPermission={isUserLogged}
              {...ourServicesSecond}
              saveService={this._saveSecondService}
              isLoading={isSecondServiceLoading}
              imgId='second'
            />
            <OurServicesItem
              hasPermission={isUserLogged}
              {...ourServicesThird}
              saveService={this._saveThirdService}
              isLoading={isThirdServiceLoading}
              imgId='third'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default OurServices;
