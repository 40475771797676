import axios from 'axios';
import {
  FETCH_OUR_SERVICES_FIRST,
  UPDATE_OUR_SERVICES_FIRST,
} from '../config/contants';

export const fetchFirstService = () => {
  return axios.get(`/firstService/`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: FETCH_OUR_SERVICES_FIRST,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const updateFirstService = (data) => {
  return axios.put(`/firstService/`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data,
  })
  .then(({status}) => {
    if (status === 200)
      return {
        type: UPDATE_OUR_SERVICES_FIRST,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};