import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import '../style/component/partner.screen.style.css';
import Partner from '../component/partner.component';
import NewPartner from '../component/new.service.component';

import {getPartners, addPartner, deletePartner, updatePartner} from '../action/partner.action';
import Loading from '../component/loading.component';
import {storage, storageRef} from '../config/firebase.config';
import firebase from 'firebase';

//TODO integration with Flow
class PartnerScreen extends PureComponent {

  state = {
    newPartnerLoading: false,
    partnersLoading: false,
  };

  componentDidMount() {
    this.setState({partnersLoading: true}, async () => {
      await this.props.getPartners();
      this.setState({partnersLoading: false});
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  _renderPartner = (item, index) => {
    const {user} = this.props;
    const isUserLogged = user !== null;
    return (
      <Partner
        key={item.id}
        partner={item}
        deletePartner={this._deletePartner}
        updatePartner={this._updatePartner}
        uploadFile={this._uploadFile}
        hasPermission={isUserLogged}
      />
    );
  };

  _deletePartner = async (partnerId, downloadURL) => {
    if (downloadURL && downloadURL !== ''){
      storage.refFromURL(downloadURL)
      .delete()
      .catch(() => console.error('Erro ao excluir imagem.'));
    }
    if (window.confirm(`Deseja excluir o parceiro?`))
      await this.props.deletePartner(partnerId);
  };

  _addPartner = () => {
    this.setState({newPartnerLoading: true}, async () => {
      await this.props.addPartner();
      this.setState({newPartnerLoading: false});
    });
  };

  _updatePartner = async (id, partner) => {
    return await this.props.updatePartner(id, partner);
  };

  _uploadFile = (partnerId, file, metadata, downloadURL, successCallback, errorCallback) => {
    if (downloadURL && downloadURL !== ''){
      storage.refFromURL(downloadURL)
      .delete()
      .catch(errorCallback);
    }

    const uploadTask = storageRef.child(`images/partners/${partnerId}-${file.name}`).put(file, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      null,
      function(error) {
        switch (error.code) {
          case 'storage/unauthorized':
            alert('Não autorizado!');
            break;

          case 'storage/canceled':
            alert('Cancelado!');
            break;


          case 'storage/unknown':
          default:
            alert('Erro desconhecido.');
            break;
        }
        errorCallback();
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        successCallback(downloadURL);
      })
  };

  render() {
    const {partners=[], user} = this.props;
    const {newPartnerLoading, partnersLoading} = this.state;
    const isUserLogged = user !== null;

    if (partnersLoading)
      return (
        <div id='partner-section' className="center-content flex-grow-1 relative">
          <Loading isLoading={partnersLoading} text='Carregando parceiros...'/>
        </div>
      );

    return (
      <div id='partner-section' className="flex flex-grow-1 row content-evenly flex-wrap center-content">
        {partners.map((item, index) => this._renderPartner(item, index))}
        {isUserLogged &&
          <NewPartner add={this._addPartner} isLoading={newPartnerLoading} text='Adicionando parceiro...'/>
        }
      </div>
    );
  }
}

const mapStateToProps = ({user, partners}) => {
  return {
    user,
    partners,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPartners: () => dispatch(getPartners()),
    addPartner: () => dispatch(addPartner()),
    deletePartner: (id) => dispatch(deletePartner(id)),
    updatePartner: (id, partner) => dispatch(updatePartner(id, partner)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerScreen);
