import {
  database,
  auth,
  storageRef,
} from '../config/firebase.config';
import {USER_SIGNED_IN, USER_SIGNED_OUT} from "../config/contants";

export const signOut = () => {
  return auth.signOut()
  .then(res => {
    return {
      type: USER_SIGNED_OUT,
      user: null,
    }
  })
  .catch(error => {
    throw error;
  });
};

export const onAuthStateChanged = (callback) => {
  auth.onAuthStateChanged(callback);
};

export const signIn = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password)
  .then(({user}) => {
    if (user) {
      return {
        type: USER_SIGNED_IN,
        user,
      }
    }

    throw new Error('Erro indefinido.');
  })
  .catch(err => {
    throw err
  });
};

export const uploadFile = (pathRef, file) => {
  const newImageRef = storageRef.child(pathRef);

  return newImageRef.put(file.currentTarget.files[0])
  .then(function (snapshot) {
    console.log('Uploaded a blob or file!', snapshot);
    return {
      type: '',
      fileUploaded: true,
    };
  })
  .catch(error => {
    console.log('Error to upload a blob or file!', error);
    return {
      type: '',
      fileUploaded: false,
    };
  });
};

export const downloadFile = (pathRef) => {
  const newImageRef = storageRef.child(pathRef);

  return newImageRef.getDownloadURL().then(function (url) {
    // `url` is the download URL for 'images/stars.jpg'

    // fetch(url, {
    //   method: 'GET',
    // }).then((res) => {
    //   console.log('getDownloadURL-fetch-then', res);
    // }).catch((error) => {
    //   console.log('getDownloadURL-fetch-catch', error);
    // });
    // This can be downloaded directly:
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.onload = function (event) {
      var blob = xhr.response;
      console.log('blob', blob);
    };
    xhr.open('GET', url);
    xhr.send();

    // Or inserted into an <img> element:
    // var img = document.getElementById('myimg');
    // img.src = url;
    console.log('getDownloadURL-then', url);
    return {
      type: '',
      url,
    };
  }).catch(function (error) {
    // Handle any errors
    console.log('getDownloadURL-catch', error);
    return {
      type: '',
      url: false,
    };
  });
};

//TODO edit - example method
export const writeData = (userId, name, email, imageUrl) => {
  database.collection('cities').doc('LA').set({
    username: name,
    email: email,
    profile_picture: imageUrl,
  })
  .then((res) => {
    console.log('_writeUserData-then', res);
  })
  .catch((error) => {
    console.log('_writeUserData-catch', error);
  });
};