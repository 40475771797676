import React, {PureComponent} from 'react';
import '../style/component/our.services.item.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import OurServicesItemText from './our.services.item.text';
import EditableText from './editable.text.component';
import {storage, storageRef} from '../config/firebase.config';
import firebase from 'firebase';

class OurServicesItem extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      textsValue: null,
      titleValue: null,
      showInputText: false,
      newText: '',
    };
  }

  _removeItem = (idx) => {
    const {texts} = this.props;
    const {textsValue} = this.state;
    const textsToRemove = textsValue || texts;

    this.setState({
      textsValue: [
        ...textsToRemove.slice(0, idx),
        ...textsToRemove.slice(idx + 1),
      ],
    });
  };

  _save = () => {
    const {saveService} = this.props;
    const {textsValue, titleValue} = this.state;
    let data = {};

    if (textsValue) {
      data = {
        ...data,
        texts: textsValue,
      }
    }

    if (titleValue) {
      data = {
        ...data,
        title: titleValue,
      }
    }

    saveService(data);
  };

  _onChangeTitle = (event) => this.setState({titleValue: event.target.value});

  _onChangeNewText = (event) => this.setState({newText: event.target.value});

  _onKeyDownNewText = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this._addText();
    }
  };

  _addText = () => {
    const {textsValue, newText} = this.state;

    if (newText !== '') {
      let newTextsValue = [];
      if (textsValue) {
        newTextsValue = [
          ...textsValue,
          newText,
        ];
      } else if (this.props.texts) {
        const {texts} = this.props;
        newTextsValue = [
          ...texts,
          newText,
        ]
      } else {
        newTextsValue = [
          newText,
        ]
      }
      this.setState({textsValue: newTextsValue, newText: '', showInputText: false});
    } else {
      this.setState({newText: '', showInputText: false});
    }
  };

  _onChangeInput = (event) => {
    const file = event.currentTarget.files[0];

    if (!file)
      return;

    // Create the file metadata
    const metadata = {
      contentType: 'image/jpeg'
    };

    const {imgId} = this.props;

    const uploadTask = storageRef.child(`images/${imgId}-service-image.jpg`).put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      function(error) {

        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;


          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
          const {saveService} = this.props;
          saveService({downloadURL});
        });
      })
  };

  render() {
    const {downloadURL, hasPermission, isLoading, texts, title, imgId} = this.props;
    const {textsValue, titleValue, showInputText, newText} = this.state;
    const textsMap = textsValue || texts || [];

    return (
      <div className="our-services-item-content flex column align-center relative">
        {isLoading ?
          <img src={require('../resource/loading.svg')} alt='carregando...' style={{width: 20, height: 20}}/>
          :
          ((textsValue && JSON.stringify(texts) !== JSON.stringify(textsValue)) || (titleValue && title !== titleValue)) &&
          <Icon
            fontFamily={'Ionicons'}
            name={'ios-save'}
            color='black'
            size={20}
            className='icon-btn absolute-top-right'
            onClick={this._save}
          />
        }
        {hasPermission ?
          <label htmlFor={`our-services-image-input-${imgId}`}>
            <div className='component-focus our-services-item-image-container'>
              <img className='our-services-item-image' src={downloadURL} alt='Erro ao carregar.'/>
            </div>
          </label>
          :
          <img className='our-services-item-image' src={downloadURL} alt='Erro ao carregar.'/>
        }
        <input
          id={`our-services-image-input-${imgId}`}
          onChange={this._onChangeInput}
          type='file'
          accept='image/*'
          style={{display: 'none'}}
        />
        <EditableText hasPermission={hasPermission} isLoading={false} text={titleValue || title || ''} classNames={'font-20 weight-700'} onChangeText={this._onChangeTitle}/>
        {
          textsMap && textsMap.map((item, idx) => {
            return (
              <OurServicesItemText key={`our-services-item-text-${idx}`} idx={idx} item={item}
                                   onClick={this._removeItem} hasPermission={hasPermission}/>
            );
          })
        }
        {showInputText &&
          <span className='flex row self-stretch'>
            <input
              autoFocus={true}
              className='font-16 weight-400 text-center font-montserrat'
              style={{flex: 1, borderBottom: '1px solid black'}}
              value={newText}
              onChange={this._onChangeNewText}
              onKeyDown={this._onKeyDownNewText}
            />
            <Icon
              fontFamily={'Ionicons'}
              name={'ios-save'}
              color='black'
              size={15}
              className='icon-btn'
              onClick={this._addText}
            />
          </span>
        }
        {
          hasPermission &&
          <Icon
            fontFamily='AntDesign'
            name='pluscircleo'
            color='black'
            size={30}
            className='icon-btn'
            onClick={() => this.setState({showInputText: true})}
          />
        }
      </div>
    );
  }
}

export default OurServicesItem;
