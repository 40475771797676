import React, {PureComponent} from 'react';
import '../style/component/invest.section.component.style.css';
import InvestSectionItem from '../component/invest.section.item.component'
import {fetchBySymbol} from '../action/invest.action';

class InvestSection extends PureComponent {

  state = {
    petr4: {},
    itsa4: {},
    ibov: {},
  };

  async componentDidMount() {
    try {
      let petr4Response = await fetchBySymbol('PETR4.SAO');
      const petr4 = this._getObject(petr4Response);
      let itsa4Response = await fetchBySymbol('ITSA4.SAO');
      const itsa4 = this._getObject(itsa4Response);
      let ibovResponse = await fetchBySymbol('BOVA11.SAO');
      const ibov = this._getObject(ibovResponse, true);
      this.setState({petr4, itsa4, ibov});
    } catch (e) {
      console.error(e);
    }
  }

  _getObject = ({data, type, error}, noRoundPrice) => {
    if (type === 'ALPHA_VANTAGE_API_ERROR') {
      console.error(error);
      return {price: '00.00', percent: '0.00', date: '00/00/0000'};
    }
    const globalQuote = data['Global Quote'];
    const price = noRoundPrice ? globalQuote['05. price'] : (Math.round(parseFloat(globalQuote['05. price']) * 100) / 100).toFixed(2);
    const percent = (Math.round(parseFloat(globalQuote['10. change percent']) * 100) / 100).toFixed(2);
    const date = globalQuote['07. latest trading day'];
    const formattedDate = `${date.substr(8, 2)}/${date.substr(5, 2)}/${date.substr(0, 4)}`;

    return {price, percent, date: formattedDate};
  };

  render() {
    const {petr4, itsa4, ibov} = this.state;
    return (
      <div id="invest-section" className="flex row align-center content-center">
        <div className='center-content flex row content-evenly flex-wrap'>
          <InvestSectionItem data={petr4} source={require('../resource/logo-PETR4.png')} imgStyle={{width: 123, height: 123}}/>
          <InvestSectionItem data={itsa4} source={require('../resource/logo_itausa.png')} />
          <InvestSectionItem data={ibov} source={require('../resource/logo-b3.png')} noFormat={true}/>
        </div>
      </div>
    );
  }
}

export default InvestSection;
