import firebase from 'firebase';

/*const config = {
  apiKey: "AIzaSyD-hEdonbrdSCHIB5Z0iQAGSKCtCvC5YLI",
  authDomain: "aaempreendimentos-3fcf3.firebaseapp.com",
  databaseURL: "https://aaempreendimentos-3fcf3.firebaseio.com",
  projectId: "aaempreendimentos-3fcf3",
  storageBucket: "aaempreendimentos-3fcf3.appspot.com",
  messagingSenderId: "589257552110",
  appId: "1:589257552110:web:e374aeda32c7c80c"
};*/

const config = {
  apiKey: "AIzaSyBeAyG35mbibs1x9Oi2i6WBnOqnL12m-0o",
  authDomain: "aaempreendimentos-eb0ed.firebaseapp.com",
  databaseURL: "https://aaempreendimentos-eb0ed.firebaseio.com",
  projectId: "aaempreendimentos-eb0ed",
  storageBucket: "aaempreendimentos-eb0ed.appspot.com",
  // messagingSenderId: "589257552110",
  // appId: "1:589257552110:web:e374aeda32c7c80c"
};

export const app = firebase.initializeApp(config);
export const auth = app.auth();
export const storage = app.storage();
export const storageRef = storage.ref();
export const database = app.firestore();
