import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import '../style/component/login.modal.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import {signIn} from '../action/firebase.action';

//TODO integration with Flow
class LoginModal extends PureComponent {

  _interval = null;
  state = {
    opacity: 0,
    email: '',
    password: '',
  };

  _show = () => {
    const {opacity} = this.state;
    let newOpacity = opacity;
    clearInterval(this._interval);

    this._interval = setInterval(() => {
      newOpacity += 0.05;
      if (newOpacity >= 1) clearInterval(this._interval);
      this.setState({opacity: newOpacity});
    }, 20);
  };

  _hide = () => {
    const {opacity} = this.state;
    clearInterval(this._interval);
    let newOpacity = opacity;

    this._interval = setInterval(() => {
      newOpacity -= 0.05;
      if (newOpacity <= 0) clearInterval(this._interval);
      this.setState({opacity: newOpacity});
    }, 20);
  };

  componentDidMount() {
    const {loginRef} = this.props;
    loginRef(this);
  }

  _onChangeEmail = (event) => this.setState({email: event.currentTarget.value});

  _onChangePassword = (event) => this.setState({password: event.currentTarget.value});

  _passwordKeyDown = ({key}) => {
    if (key === 'Enter') this._login();
  };

  _login = () => {
    const {email, password} = this.state;
    this.props.signIn(email, password)
    .then(res => {
      if (res.user) {
        this.setState({email: '', password: ''}, this._hide);
      }
    })
    .catch(err => {
      if (err.code === 'auth/invalid-email' || err.code === 'auth/wrong-password') {
        alert('Email ou senha incorretos.')
      }
      console.error('Por favor entre em contato com o suporte.', err);
    });
  };

  render() {
    const {opacity, email, password} = this.state;

    return (
      <div id='login-modal-component' className="full-fixed flex column content-center align-center" style={{opacity, display: opacity <= 0 ? 'none' : 'flex'}}>
        <div className='login-container flex column align-stretch padding-15'>
          <Icon
            fontFamily={'AntDesign'}
            name={'close'}
            color='#000'
            size={25}
            className='login-close-icon'
            onClick={this._hide}
          />
          <p className='login-title font-18 weight-700'>Faça seu login</p>

          <div className='flex flex-1 column content-center align-stretch'>
            <div className='flex column align-stretch login-container-input'>
              <input onChange={this._onChangeEmail} className='font-16 weight-400 login-input' placeholder='Informe seu email' value={email} type='text'/>
              <input onKeyDown={this._passwordKeyDown} onChange={this._onChangePassword} className='font-16 weight-400 login-input' placeholder='Informe sua senha' value={password} type='password'/>
            </div>
          </div>
          <div className='flex content-center align-stretch self-center login-btn' onClick={this._login}>
            <span className='flex-1 padding-10-15 login-btn-content'>LOGIN</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({isLoadingVisible}) => {
  return {
    isLoadingVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (email, password) => dispatch(signIn(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
