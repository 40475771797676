import React, {PureComponent} from 'react';
import '../style/component/about.us.component.style.css';
import EditableText from './editable.text.component';

class AboutUs extends PureComponent {

  state = {
    isTitleLoading: false,
    isTextLoading: false,
  };

  _saveTitle = (title) => {
    this.setState({
        isTitleLoading: true,
    },
      () => {
          this.props.updateAboutUs({title})
          .then(_ => this.setState({isTitleLoading: false}))
          .catch(err => this.setState({isTitleLoading: false}));
        }
    );
  };

  _saveText = (text) => {
    this.setState({
        isTextLoading: true,
    },
      () => {
          this.props.updateAboutUs({text})
          .then(_ => this.setState({isTextLoading: false}))
          .catch(err => this.setState({isTextLoading: false}));
        }
    );
  };

  render() {
    const {isTitleLoading, isTextLoading} = this.state;
    const {title, text, isUserLogged} = this.props;

    return (
      <div id="about-us-section" className="flex column align-center">
        <div className='center-content flex column align-center'>
          <EditableText hasPermission={isUserLogged} isLoading={isTitleLoading} save={this._saveTitle} text={title} classNames={'font-30 weight-700'}/>
          <EditableText hasPermission={isUserLogged} isLoading={isTextLoading} save={this._saveText} text={text} classNames={'font-18 weight-400 text-center'}/>
          {/*<p className='font-18 weight-400 text-center'><span className='weight-700'>Visão de Longo Prazo</span> - Presamos atingir a nossa meta, mas sempre com planejamento para as armadilhas do curto prazo.</p>*/}
          {/*<p className='font-18 weight-400 text-center'><span className='weight-700'>Ética</span> - Desenvolver serviços sempre partindo de conceitos corretos.</p>*/}
          {/*<p className='font-18 weight-400 text-center'><span className='weight-700'>Qualidade nos Serviços</span> - Ser feito da melhor maneira para atingir as necessidades de seus clientes.</p>*/}
        </div>
      </div>
    );
  }
}

export default AboutUs;
