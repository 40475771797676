import {
  FETCH_BANNER_IMAGE,
  UPDATE_BANNER_IMAGE,
} from '../config/contants';

export default (state = {
  banner_1: require('../resource/banner.jpg'),
  banner_2: require('../resource/banner-2.jpg'),
  banner_3: require('../resource/banner-3.jpg'),
  banner_4: require('../resource/banner-4.jpg'),
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_BANNER_IMAGE:
      return {
        ...state,
        ...data
      };
    case FETCH_BANNER_IMAGE:
      return data;
    default:
      return state;
  }
}
