import {
  FETCH_OUR_SERVICES,
  UPDATE_OUR_SERVICES,
} from '../config/contants';

export default (state = {
  title: 'Nossos Serviços',
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_OUR_SERVICES:
      return {
        ...state,
        ...data
      };
    case FETCH_OUR_SERVICES:
      return data;
    default:
      return state;
  }
}