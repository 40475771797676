import {
  ADD_SERVICE,
  FETCH_SERVICES,
  FETCH_SERVICES_ERROR,
} from '../config/contants';

export default (state = [], {type, data}) => {
  switch (type) {
    case FETCH_SERVICES:
      return data;
    case ADD_SERVICE:
      return [
        ...state,
        data,
      ];
    case FETCH_SERVICES_ERROR:
    default:
      return state;
  }
}