import React, {PureComponent} from 'react';
import '../style/component/list.modal.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import _ from 'lodash';

class ListModalItem extends PureComponent {

  state = {
    isDeleteLoading: false,
  };

  _deletePage = (id) => {
    const formattedId = _.replace(id, /[_]/g, ' ');
    if(window.confirm(`Deseja remover a empresa ${formattedId}?`)) {
      this.setState(
        {isDeleteLoading: true},
        async () => {
          await this.props.deletePage(id);
          this.setState({isDeleteLoading: false});
        });
    }
  };

  render() {
    const {id, hasPermission, selectedCompany, redirect, getCompanies} = this.props;
    const {isDeleteLoading} = this.state;
    const formattedId = _.replace(id, /[_]/g, ' ');
    return(
      <div className='flex row content-between relative'>
        <div onClick={() => {
          getCompanies(id);
          redirect(id);
        }} className='list-modal-content-item flex-1'>
          {formattedId}
        </div>
        {isDeleteLoading &&
          <img className='absolute-top-right' src={require('../resource/loading.svg')} alt='carregando...' style={{width: 20, height: 20}}/>
        }
        {hasPermission && selectedCompany.id !== id && !isDeleteLoading &&
        <Icon
          fontFamily={'AntDesign'}
          name='minuscircleo'
          color='#F00'
          size={15}
          className='icon-btn absolute-top-right'
          onClick={() => this._deletePage(id)}
        />
        }
      </div>
    )
  }
}

//TODO integration with Flow
class ListModal extends PureComponent {

  static Item = ListModalItem;

  _interval = null;
  state = {
    opacity: 0,
    showInputText: false,
    newPage: '',
  };

  _show = () => {
    const {opacity} = this.state;
    let newOpacity = opacity;
    clearInterval(this._interval);

    this._interval = setInterval(() => {
      newOpacity += 0.05;
      if (newOpacity >= 1) clearInterval(this._interval);
      this.setState({opacity: newOpacity});
    }, 20);
  };

  _hide = () => {
    const {opacity} = this.state;
    clearInterval(this._interval);
    let newOpacity = opacity;

    this._interval = setInterval(() => {
      newOpacity -= 0.05;
      if (newOpacity <= 0) clearInterval(this._interval);
      this.setState({opacity: newOpacity});
    }, 20);
  };

  componentDidMount() {
    const {modalRef} = this.props;
    modalRef(this);
  }

  _onChangeNewPage = (event) => this.setState({newPage: event.target.value});

  _onKeyDownNewPage = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this._addPage();
    }
  };

  _addPage = () => {
    const {newPage} = this.state;
    if (newPage === '') {
      this.setState({showInputText: false});
    } else {
      this.setState({newPage: '', showInputText: false}, () => this.props.newPage(_.replace(newPage, /[ ]/g, '_').toLowerCase()));
    }
  };

  render() {
    const {opacity, showInputText, newPage} = this.state;
    const {data = [], isLoading, hasPermission, selectedCompany, deletePage, redirect, getCompanies} = this.props;

    return (
      <div className="full-fixed flex column content-center align-center" style={{opacity, display: opacity <= 0 ? 'none' : 'flex', backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
        <div className='list-modal-content flex column align-stretch content-center relative' style={{backgroundColor: '#FFF'}}>
          <Icon
            fontFamily={'AntDesign'}
            name={'close'}
            color='#000'
            size={25}
            className='absolute-top-right self-end'
            style={{cursor: 'pointer'}}
            onClick={this._hide}
          />
          {
            data.map((id, idx) => <ListModal.Item key={id} id={id} getCompanies={getCompanies} redirect={redirect} deletePage={deletePage} hasPermission={hasPermission} selectedCompany={selectedCompany}/>)
          }

          {showInputText &&
            <span className='flex row self-stretch'>
              <input
                autoFocus={true}
                className='font-16 weight-400 text-center font-montserrat'
                style={{flex: 1, borderBottom: '1px solid black'}}
                value={newPage}
                onChange={this._onChangeNewPage}
                onKeyDown={this._onKeyDownNewPage}
              />
              <Icon
                fontFamily={'Ionicons'}
                name={'ios-save'}
                color='black'
                size={15}
                className='icon-btn'
                onClick={this._addPage}
              />
            </span>
          }
          {isLoading &&
            <img className='self-center' src={require('../resource/loading.svg')} alt='carregando...' style={{width: 20, height: 20}}/>
          }
          {hasPermission &&
            <Icon
              fontFamily={'AntDesign'}
              name={'pluscircleo'}
              color='#000'
              size={30}
              className='self-center icon-btn'
              onClick={() => this.setState({showInputText: true})}
            />
          }
        </div>
      </div>
    );
  }
}

export default ListModal;
