import React, {PureComponent} from 'react';
import Icon from '../style/fonts/glyphmaps/icon.component';

export default class OurServicesItemText extends PureComponent {

  state = {
    showEditBtn: false,
  };

  _onMouseEnter = () => this.setState({showEditBtn: true});

  _onMouseLeave = () => this.setState({showEditBtn: false});

  render() {
    const {idx, item, onClick, hasPermission} = this.props;
    const {showEditBtn} = this.state;
    return (
      <span className='font-16 weight-400 text-center relative self-stretch' onMouseEnter={this._onMouseEnter} onMouseLeave={this._onMouseLeave}>
        {item}
        {hasPermission && showEditBtn &&
          <Icon
            fontFamily='AntDesign'
            name='minuscircleo'
            color='#F00'
            size={15}
            className='icon-btn absolute-top-right'
            onClick={() => onClick(idx)}
          />
        }
      </span>
    );
  }
}