import {
  FETCH_ABOUT_US,
  UPDATE_ABOUT_US,
} from '../config/contants';

export default (state = {
  title: 'Quem Somos',
  text: 'A AA empreendimentos é uma empresa que atua no mercado financeiro e em serviços de engenharia civil proporcionando sempre a melhor solução para seus clientes.',
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_ABOUT_US:
      return {
        ...state,
        ...data
      };
    case FETCH_ABOUT_US:
      return data;
    default:
      return state;
  }
}