import React, {PureComponent} from 'react';
import '../style/component/toggle.banner.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import EditableText from './editable.text.component';
import {storageRef} from '../config/firebase.config';
import firebase from 'firebase';

class ToggleBanner extends PureComponent {

  _interval = null;
  _fadeInterval = null;
  state = {
    opacity: 1,
    bannerPage: 1,
    isLoading: false,
    isImageUpdating: false,
  };

  componentDidMount() {
    this._initInterval();
  }

  _initInterval = () => {
    this._interval = setInterval(() => {
      this._toggleFade();
      clearInterval(this._interval);
    }, 15000);
  };

  _getBannerText = (bannerPage) => {
    const {civil_constr, consult_invest, financial_market, our_partner} = this.props;
    switch (bannerPage) {
      case 1:
        return civil_constr;
      case 2:
        return consult_invest;
      case 3:
        return financial_market;
      case 4:
        return our_partner;
      default:
        return civil_constr;
    }
  };

  _toggleFade = (bannerPageAction) => {
    const {bannerPage} = this.state;
    let opacity = this.state.opacity;

    this._fadeInterval = setInterval(() => {
      opacity = opacity - 0.02;
      this.setState({opacity});
      if (opacity <= 0.8) {
        clearInterval(this._fadeInterval);
        this.setState({
          bannerPage: bannerPageAction ? bannerPageAction : (bannerPage === 4 ? 1 : bannerPage + 1),
          bannerText: this._getBannerText(bannerPageAction ? bannerPageAction : (bannerPage + 1)),
        }, () => {
          this._fadeInterval = setInterval(() => {
            opacity = opacity + 0.02;
            this.setState({opacity});
            if (opacity >= 1) {
              clearInterval(this._fadeInterval);
              this._initInterval();
            }
          }, 50);
        });
      }
    }, 50);
  };

  _backSlide = () => {
    clearInterval(this._interval);
    clearInterval(this._fadeInterval);
    const {bannerPage} = this.state;
    const bannerPageAction = bannerPage - 1 === 0 ? 4 : bannerPage - 1;
    this._toggleFade(bannerPageAction);
  };

  _forwardSlide = () => {
    clearInterval(this._interval);
    clearInterval(this._fadeInterval);
    const {bannerPage} = this.state;
    const bannerPageAction = bannerPage + 1 === 5 ? 0 : bannerPage + 1;
    this._toggleFade(bannerPageAction);
  };

  _stopSlide = () => {
    clearInterval(this._interval);
    clearInterval(this._fadeInterval);
  };

  _startSlide = this._initInterval;

  _updateBannerTextList = async (text) => {
    const {updateBannerTextList} = this.props;
    const {bannerPage} = this.state;
    this.setState({
      isLoading: true
    },
      async () => {
        try {
          let data =  {};
          switch (bannerPage) {
            case 1:
              data = {civil_constr: text};
              break;
            case 2:
              data = {consult_invest: text};
              break;
            case 3:
              data = {financial_market: text};
              break;
            case 4:
              data = {our_partner: text};
              break;
            default:
              break;
          }
          await updateBannerTextList(data);
        } catch (e) {
          alert(`Erro ao atualizar texto do banner`);
          console.error(`Erro ao atualizar texto do banner - ${e}`);
        } finally {
          this.setState({isLoading: false}, this._startSlide);
        }
      }
    );
  };

  _onChangeInput = (event) => {
    const file = event.currentTarget.files[0];

    if (!file)
      return;

    const splitName = file.name.split('.');
    const ext = splitName[splitName.length - 1];

    const {bannerPage} = this.state;

    // Create the file metadata
    const metadata = {
      contentType: file.type
    };

    this.setState({isImageUpdating: true}, () => {
      const uploadTask = storageRef.child(`images/banner/banner-img-${bannerPage}.${ext}`).put(file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        },
        function(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              console.error('storage/unauthorized');
              break;
            case 'storage/canceled':
              // User canceled the upload
              console.error('storage/canceled');
              break;
            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              console.error('storage/unknown');
              break;
            default:
              console.error('storage/default');
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            console.log('File available at');
            console.log(downloadURL);
            let data = {};
            data[`banner_${this.state.bannerPage}`] = downloadURL;
            await this.props.updateBannerImage(data);
            this._startSlide();
            this.setState({isImageUpdating: false});
          });
        });
    });
  };

  render() {
    const {bannerPage, opacity, isLoading, isImageUpdating} = this.state;
    const {isUserLogged, banner_1, banner_2, banner_3, banner_4} = this.props;

    return (
      <div id='toggle-banner' className='flex row content-between align-stretch relative'>
        {isUserLogged &&
          <>
            {isImageUpdating ?
              <img className='upload-banner-image' src={require('../resource/loading.svg')} alt='carregando...' style={{width: 20, height: 20}}/>
              :
              <label htmlFor='banner-image-input' onClick={this._stopSlide}>
                <Icon
                  fontFamily='FontAwesome'
                  name='image'
                  color='#FFF'
                  size={23}
                  className='icon-btn upload-banner-image'
                />
              </label>
            }
            <input
              id={`banner-image-input`}
              onChange={this._onChangeInput}
              type='file'
              accept='image/*'
              style={{display: 'none'}}
            />
          </>
        }
        <div className='banner-img' style={{opacity: bannerPage === 1 ? opacity : 0, backgroundImage: `url(${banner_1})`}}/>
        <div className='banner-img' style={{opacity: bannerPage === 2 ? opacity : 0, backgroundImage: `url(${banner_2})`}}/>
        <div className='banner-img' style={{opacity: bannerPage === 3 ? opacity : 0, backgroundImage: `url(${banner_3})`}}/>
        <div className='banner-img' style={{opacity: bannerPage === 4 ? opacity : 0, backgroundImage: `url(${banner_4})`}}/>
        <div className='slider-icon-container flex align-center content-center' onClick={this._backSlide}>
          <Icon
            fontFamily='AntDesign'
            name='left'
            color='#FFF'
            size={30}
            className='slider-icon'
          />
        </div>
        <div id='banner-text' className='toggle-banner-content flex align-end content-stretch'>
          <EditableText
            hasPermission={isUserLogged}
            isLoading={isLoading}
            save={this._updateBannerTextList}
            text={this._getBannerText(bannerPage)}
            classNames={'font-36 weight-700 text-left-important banner-text'}
            iconColor='#FFFFFF'
            onEnableEdit={this._stopSlide}
            onFinishEdit={this._startSlide}
            inputStyle={{color: '#FFFFFF'}}
          />
        </div>
        <div className='slider-icon-container flex align-center content-center' onClick={this._forwardSlide}>
          <Icon
            fontFamily='AntDesign'
            name='right'
            color='#FFF'
            size={30}
            className='slider-icon'
          />
        </div>
      </div>
    )
  }
}

export default ToggleBanner;
