import React, {PureComponent} from 'react';
import '../style/component/footer.component.style.css';
import FooterIcons from './footer.icons.component';
import {updateFooterIcons, fetchFooterIcons} from '../action/footer.icons.action';
import {updateFooterContactTextList, fetchFooterContactTextList} from '../action/footer.contact.text.action';
import {connect} from 'react-redux';
import EditableText from './editable.text.component';

//TODO integration with Flow
class Footer extends PureComponent {

  state = {
    isEmailLoading: false,
    isMobilePhoneLoading: false,
    isPhoneLoading: false,
  };

  componentDidMount() {
    this.props.fetchFooterContactTextList();
  }

  _updateFooterContactEmail = (email) => {
    this.setState({isEmailLoading: true}, async () => {
      try {
        await this.props.updateFooterContactTextList({email});
      } finally {
        this.setState({isEmailLoading: false});
      }
    });
  };

  _updateFooterContactMobilePhone = (mobile_phone) => {
    this.setState({isMobilePhoneLoading: true}, async () => {
      try {
        await this.props.updateFooterContactTextList({mobile_phone});
      } finally {
        this.setState({isMobilePhoneLoading: false});
      }
    });
  };

  _updateFooterContactPhone = (phone) => {
    this.setState({isPhoneLoading: true}, async () => {
      try {
        await this.props.updateFooterContactTextList({phone});
      } finally {
        this.setState({isPhoneLoading: false});
      }
    });
  };

  render() {
    const {isEmailLoading, isMobilePhoneLoading, isPhoneLoading} = this.state;
    const {user, footerIcons, updateFooterIcons, fetchFooterIcons, footerContactTextList: {email, mobile_phone, phone}} = this.props;
    const isUserLogged = user !== null;

    return (
      <footer className="app-footer flex row content-center">
        <div className="center-content flex app-footer-content">
          <div>
            <p className='font-14 weight-700'>AA Empreendimentos</p>
            <p className='font-12 weight-400'>© {new Date().getFullYear()} AA Empreendimentos.<br/>Todos os direitos reservados.</p>
          </div>
          <div className='flex column'>
            <EditableText
              hasPermission={isUserLogged}
              isLoading={isEmailLoading}
              save={this._updateFooterContactEmail}
              text={email}
              classNames={'font-12 weight-400'}
              iconColor='#FFFFFF'
              iconPosition={{top: -2, right: -5}}
              inputStyle={{color: '#FFFFFF'}}
            />
            <EditableText
              enableEmpty={true}
              hasPermission={isUserLogged}
              isLoading={isMobilePhoneLoading}
              save={this._updateFooterContactMobilePhone}
              text={mobile_phone}
              classNames={'font-12 weight-400'}
              iconColor='#FFFFFF'
              iconPosition={{top: -2, right: -5}}
              inputStyle={{color: '#FFFFFF'}}
            />
            {/*<EditableText
              hasPermission={isUserLogged}
              isLoading={isPhoneLoading}
              save={this._updateFooterContactPhone}
              text={phone}
              classNames={'font-12 weight-400 text-left-important'}
              iconColor='#FFFFFF'
              iconPosition={{top: -2, right: -5}}
              inputStyle={{color: '#FFFFFF'}}
            />*/}
          </div>
          <FooterIcons fetchFooterIcons={fetchFooterIcons} hasPermission={isUserLogged} {...footerIcons} updateFooterIcons={updateFooterIcons}/>
        </div>
      </footer>
    );
  }
}

function mapStateToProps({user, footerIcons, footerContactTextList}) {
  return {
    user,
    footerIcons,
    footerContactTextList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFooterIcons: () => dispatch(fetchFooterIcons()),
    updateFooterIcons: (data) => dispatch(updateFooterIcons(data)),
    fetchFooterContactTextList: () => dispatch(fetchFooterContactTextList()),
    updateFooterContactTextList: (data) => dispatch(updateFooterContactTextList(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
