import React, {PureComponent} from 'react';
import '../style/component/header.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {signedIn} from '../action/user.action';
import {fetchCompanyPages, addCompanyPage, deleteCompanyPage} from '../action/company.page.action';
import {onAuthStateChanged, signOut} from '../action/firebase.action';
import LoginModal from './login.modal.component';
import ListModal from './list.modal.component';
import {ADD_COMPANY_PAGE_SUCCESS} from '../config/contants';
import {getCompanies} from '../action/selected.company.action';

//TODO integration with Flow
//TODO BUG ao abrir menu com tela para mobile e redimencionar o menu buga.
class Header extends PureComponent {

  _fadeInterval = null;

  constructor(props) {
    super(props);

    this.state = {
      opacity: 1,
      menuOpacity: 0,
      isMenuHiding: true,
      loginRef: null,
      listModalCompanyPages: null,
      isLoadingNewCompany: false,
      listModalPartners: null,
    };

    onAuthStateChanged(user => {
      this.props.signedIn(user);
    });

    props.fetchCompanyPages();
  }

  _scrollTo = ({currentTarget}) => {
    const top = document.getElementById(currentTarget.getAttribute('data-scroll')).offsetTop - 80;
    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  };

  _checkPageYOffset = () => {
    this.setState({opacity: window.pageYOffset < 10 ? 1 : 0.4});
  };

  componentDidMount() {
    window.onscroll = this._checkPageYOffset;
  }

  _hideMenu = () => {
    let menuOpacity = this.state.menuOpacity;

    this._fadeInterval = setInterval(() => {
      menuOpacity -= 0.1;
      this.setState({menuOpacity});
      if (menuOpacity <= 0) {
        clearInterval(this._fadeInterval);
        this.setState({menuOpacity: 0});
      }
    }, 50);
  };

  _showMenu = () => {
    let menuOpacity = this.state.menuOpacity;

    this._fadeInterval = setInterval(() => {
      menuOpacity += 0.1;
      this.setState({menuOpacity});
      if (menuOpacity >= 1) {
        clearInterval(this._fadeInterval);
        this.setState({menuOpacity: 1});
      }
    }, 50);
  };

  _toggleMenu = () => {
    const {isMenuHiding} = this.state;

    clearInterval(this._fadeInterval);
    if (isMenuHiding) {
      this.setState(
        {isMenuHiding: !isMenuHiding},
        () => {
        this._showMenu();
        });
    } else {
      this.setState(
        {isMenuHiding: !isMenuHiding},
        () => {
          this._hideMenu();
        });
    }
  };

  _showCompanyPages = () => {
    const {listModalCompanyPages, menuOpacity} = this.state;
    listModalCompanyPages._show();
    if (menuOpacity > 0 ) this._toggleMenu();
  };

  _addCompanyPage = (id) => {
    this.setState({isLoadingNewCompany: true}, async () => {
      try {
        const {type, message} = await this.props.addCompanyPage(id);
        this.setState({isLoadingNewCompany: false});
        if (ADD_COMPANY_PAGE_SUCCESS === type) {
          alert(message);
        }
      } catch (e) {
        this.setState({isLoadingNewCompany: false});
        alert('ERRO!');
        console.warn(e);
      }
    });
  };

  _deleteCompanyPage = async (id) => {
    await this.props.deleteCompanyPage(id);
  };

  _companyRedirect = async (companyPage) => {
    this.state.listModalCompanyPages._hide();
    this.props.history.push(`/empresa/${companyPage}`);
  };

  _homeRedirect = () => {
    this.state.menuOpacity > 0 && this._toggleMenu();
    this.props.history.push(`/`);
  };

  _partnerRedirect = () => {
    if (this.state.menuOpacity > 0 ) this._toggleMenu();
    this.props.history.push(`/partner`);
  };

  render() {
    const {opacity, menuOpacity, isMenuHiding, loginRef, isLoadingNewCompany} = this.state;
    const {user, signOut, companyPages, selectedCompany, getCompanies, match: {isExact}} = this.props;
    const isUserLogged = user !== null;

    return (
      <header className="app-header full-fixed flex row content-center" style={{opacity}}>
        <div className="center-content app-header-content flex row align-stretch content-between">
          <img onClick={this._homeRedirect} className="header-logo" src={require('../resource/aaempreendimentos.png')} alt='Erro ao carregar.'/>
          <Icon
            fontFamily={isMenuHiding ? 'Entypo' : 'AntDesign'}
            name={isMenuHiding ? 'menu' : 'close'}
            color='#FFF'
            size={40}
            className='header-icon'
            onClick={this._toggleMenu}
          />
          <div id="header-menu" className="flex row align-center content-start" style={{opacity: menuOpacity, display: menuOpacity <= 0 ? 'none' : 'block'}}>
            {isExact &&
              <span data-scroll="about-us-section" onClick={this._scrollTo} className="header-menu-item flex column align-center content-center font-12 weight-700"><span>Quem</span><span className='space-span'/><span>Somos</span></span>
            }
            <span onClick={this._showCompanyPages} className="header-menu-item flex column align-center content-center font-12 weight-700">Empresas</span>
            <span onClick={this._partnerRedirect} className="header-menu-item flex column align-center content-center font-12 weight-700">Parceiros</span>
            <span data-scroll="contact-section" onClick={this._scrollTo} className="header-menu-item flex column align-center content-center font-12 weight-700">Contato</span>
            {!isUserLogged ?
              <span data-scroll="contact-section" onClick={loginRef !== null ? loginRef._show : null} className="header-menu-item flex column align-center content-center font-12 weight-700">Log-in</span>
              :
              <span data-scroll="contact-section" onClick={signOut} className="header-menu-item flex column align-center content-center font-12 weight-700">Log-out</span>
            }
          </div>
        </div>
        <ListModal getCompanies={getCompanies} selectedCompany={selectedCompany} hasPermission={isUserLogged} deletePage={this._deleteCompanyPage} redirect={this._companyRedirect} isLoading={isLoadingNewCompany} newPage={this._addCompanyPage} modalRef={listModalCompanyPages => this.setState({listModalCompanyPages})} data={companyPages}/>
        <LoginModal loginRef={loginRef => this.setState({loginRef})}/>
      </header>
    );
  }
}

function mapStateToProps({user, companyPages, selectedCompany}) {
  return {
    user,
    companyPages,
    selectedCompany,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signedIn: (user) => dispatch(signedIn(user)),
    signOut: () => dispatch(signOut()),
    fetchCompanyPages: () => dispatch(fetchCompanyPages()),
    addCompanyPage: (id) => dispatch(addCompanyPage(id)),
    deleteCompanyPage: (id) => dispatch(deleteCompanyPage(id)),
    getCompanies: (id) => dispatch(getCompanies(id)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
