import {
  FETCH_OUR_SERVICES_SECOND,
  UPDATE_OUR_SERVICES_SECOND,
} from '../config/contants';

export default (state = {
  title: 'Seja nosso Parceiro',
  texts: [],
  downloadURL: require('../resource/house_poster_u6377.png'),
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_OUR_SERVICES_SECOND:
      return {
        ...state,
        ...data
      };
    case FETCH_OUR_SERVICES_SECOND:
      return data;
    default:
      return state;
  }
}