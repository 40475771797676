import React, {PureComponent} from 'react';
import '../style/component/new.service.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import Loading from './loading.component';

//TODO integration with Flow
class NewService extends PureComponent {
  render() {
    const {isLoading, text='Adicionando empresa...', add} = this.props;
    return (
      <div
        className="flex column content-center align-center relative new-service-container new-service-container-icon"
      >
        {isLoading ?
          <Loading isLoading={isLoading} text={text}/>
          :
          <Icon
            fontFamily='AntDesign'
            name='pluscircleo'
            color='black'
            size={60}
            className='add-service-icon'
            onClick={add}
          />
        }
      </div>
    );
  }
}

export default NewService;
