import {
  ADD_COMPANY_PAGE_SUCCESS, DELETE_COMPANY_PAGE_SUCCESS,
  FETCH_COMPANY_PAGES,
} from '../config/contants';

export default (state = [], action) => {
  const {type, data, company} = action;
  switch (type) {
    case FETCH_COMPANY_PAGES:
      return data;
    case ADD_COMPANY_PAGE_SUCCESS:
      return [
        ...state,
        company,
      ];
    case DELETE_COMPANY_PAGE_SUCCESS:
      return state.filter(item => item !== company);
    default:
      return state;
  }
}