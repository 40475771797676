import React, {PureComponent} from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './screen/home.screen';
import Company from './screen/company.screen';
import Partner from './screen/partner.screen';
import Header from './component/header.component';
import Footer from './component/footer.component';
import Contact from './component/contact.component';

class App extends PureComponent {
  render() {
    return (
      <Router>
        <Header />
        <Route exact path='/' component={Home}/>
        <Route exact path='/empresa/:id' component={Company}/>
        <Route exact path='/partner/' component={Partner}/>
        <Contact />
        <Footer />
      </Router>
    );
  }
}

export default App;
