import axios from 'axios';
import {
  FETCH_COMPANY_PAGES,
  ADD_COMPANY_PAGE_SUCCESS, DELETE_COMPANY_PAGE_SUCCESS,
} from '../config/contants';

export const fetchCompanyPages = () => {
  return axios.get(`/company_pages/`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: FETCH_COMPANY_PAGES,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const addCompanyPage = (id) => {
  return axios.post(`/company_pages/`, {id})
  .then(({data, status}) => {
    console.warn('addCompanyPage', data);
    if (status === 200)
      return {
        type: ADD_COMPANY_PAGE_SUCCESS,
        company: id,
        message: data.message,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const deleteCompanyPage = (id) => {
  return axios.delete(`/company_pages/${id}`)
  .then(({data, status}) => {
    console.warn('deleteCompanyPage', data);
    if (status === 200)
      return {
        type: DELETE_COMPANY_PAGE_SUCCESS,
        company: id,
        message: data.message,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};
