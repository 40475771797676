import {
  FETCH_OUR_SERVICES_FIRST,
  UPDATE_OUR_SERVICES_FIRST,
} from '../config/contants';

export default (state = {
  title: 'Construção Civil',
  texts: [
    'Serviços Elétricos',
    'Serviços de Gesso',
    'Execução de Obras',
    'Instalação de Portas Automatizadas',
    'Projetos Civil, hidráulicos, elétricos, AVCB',
    'Remoção e Instalação de Estruturas',
    'Metálicas',
  ],
  downloadURL: require('../resource/screen_poster_u6216.png'),
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_OUR_SERVICES_FIRST:
      return {
        ...state,
        ...data
      };
    case FETCH_OUR_SERVICES_FIRST:
      return data;
    default:
      return state;
  }
}