import React, {PureComponent} from 'react';
import ToggleBanner from '../component/toggle.banner.component';
import AboutUs from '../component/about.us.component';
import InvestSection from '../component/invest.section.component';
import OurServices from '../component/our.services.component';
import {connect} from "react-redux";
import {updateAboutUs, fetchAboutUs} from '../action/about.us.action';
import {updateOurServices, fetchOurServices} from '../action/our.services.action';
import {updateFirstService, fetchFirstService} from '../action/our.services.first.action';
import {updateSecondService, fetchSecondService} from '../action/our.services.second.action';
import {updateThirdService, fetchThirdService} from '../action/our.services.third.action';
import {updateBannerTextList, fetchBannerTextList} from '../action/banner.slide.text.action';
import {updateBannerImage, fetchBannerImage} from '../action/banner.image.action';

class Home extends PureComponent {

  componentDidMount() {
    const {
      fetchAboutUs,
      fetchOurServices,
      fetchFirstService,
      fetchSecondService,
      fetchThirdService,
      fetchBannerTextList,
      fetchBannerImage,
    } = this.props;
    fetchAboutUs();
    fetchOurServices();
    fetchFirstService();
    fetchSecondService();
    fetchThirdService();
    fetchBannerTextList();
    fetchBannerImage();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const {
      user,
      updateAboutUs,
      aboutUs,
      updateOurServices,
      ourServices,
      updateFirstService,
      ourServicesFirst,
      updateSecondService,
      ourServicesSecond,
      updateThirdService,
      ourServicesThird,
      bannerTextList,
      updateBannerTextList,
      updateBannerImage,
      bannerImage,
    } = this.props;
    const isUserLogged = user !== null;

    return (
      <div className='flex-1 flex-grow-1'>
        <ToggleBanner updateBannerImage={updateBannerImage} {...bannerImage} isUserLogged={isUserLogged} {...bannerTextList} updateBannerTextList={updateBannerTextList}/>
        <div className="flex column align-stretch">
          <AboutUs {...aboutUs} updateAboutUs={updateAboutUs} isUserLogged={isUserLogged}/>
          <OurServices
            {...ourServices}
            updateOurServices={updateOurServices}
            updateFirstService={updateFirstService}
            ourServicesFirst={ourServicesFirst}
            updateSecondService={updateSecondService}
            ourServicesSecond={ourServicesSecond}
            updateThirdService={updateThirdService}
            ourServicesThird={ourServicesThird}
            isUserLogged={isUserLogged}
          />
          <InvestSection/>
        </div>
      </div>
    );
  }
}

function mapStateToProps({user, aboutUs, ourServices, ourServicesFirst, ourServicesSecond, ourServicesThird, bannerTextList, bannerImage}) {
  return {
    user,
    aboutUs,
    ourServices,
    ourServicesFirst,
    ourServicesSecond,
    ourServicesThird,
    bannerTextList,
    bannerImage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAboutUs: () => dispatch(fetchAboutUs()),
    updateAboutUs: (data) => dispatch(updateAboutUs(data)),
    fetchOurServices: () => dispatch(fetchOurServices()),
    updateOurServices: (data) => dispatch(updateOurServices(data)),
    fetchFirstService: () => dispatch(fetchFirstService()),
    updateFirstService: (data) => dispatch(updateFirstService(data)),
    fetchSecondService: () => dispatch(fetchSecondService()),
    updateSecondService: (data) => dispatch(updateSecondService(data)),
    fetchThirdService: () => dispatch(fetchThirdService()),
    updateThirdService: (data) => dispatch(updateThirdService(data)),
    fetchBannerTextList: () => dispatch(fetchBannerTextList()),
    updateBannerTextList: (data) => dispatch(updateBannerTextList(data)),
    fetchBannerImage: () => dispatch(fetchBannerImage()),
    updateBannerImage: (data) => dispatch(updateBannerImage(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
