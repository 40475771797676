import axios from 'axios';
import {
  FETCH_FOOTER_CONTACT_TEXT,
  UPDATE_FOOTER_CONTACT_TEXT,
} from '../config/contants';

export const fetchFooterContactTextList = () => {
  return axios.get(`/footerContactTextList/`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: FETCH_FOOTER_CONTACT_TEXT,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const updateFooterContactTextList = (data) => {
  return axios.put(`/footerContactTextList/`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data,
  })
  .then(({status}) => {
    if (status === 200)
      return {
        type: UPDATE_FOOTER_CONTACT_TEXT,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};
