import {
  TOGGLE_LOADING,
} from '../config/contants';

export default (state = false, {type}) => {
  switch (type) {
    case TOGGLE_LOADING:
      return !state;
    default:
        return state;
  }
}