import {combineReducers} from 'redux';
import testReducer from './test.reducer';
import aboutUs from './about.us.reducer';
import footerIcons from './footer.icons.reducer';
import ourServices from './our.services.reducer';
import ourServicesFirst from './our.services.first.reducer';
import ourServicesSecond from './our.services.second.reducer';
import ourServicesThird from './our.services.third.reducer';
import user from './user.reducer';
import serviceList from './service.reducer';
import isEditVisible from './toggle.service.reducer';
import isLoadingVisible from './toggle.loading.reducer';
import companyPages from './company.page.reducer';
import selectedCompany from './selected.company.reducer';
import partners from './partner.reducer';
import bannerTextList from './banner.slide.text.reducer';
import footerContactTextList from './footer.contact.text.reducer';
import bannerImage from './banner.image.reducer';

export default combineReducers({
  testReducer,
  user,
  serviceList,
  isEditVisible,
  isLoadingVisible,
  aboutUs,
  ourServices,
  ourServicesFirst,
  ourServicesSecond,
  ourServicesThird,
  footerIcons,
  companyPages,
  selectedCompany,
  partners,
  bannerTextList,
  footerContactTextList,
  bannerImage,
});
