import {
  ADD_PARTNER_SUCCESS,
  DELETE_PARTNER_SUCCESS,
  FETCH_PARTNERS_SUCCESS,
  UPDATE_PARTNER_SUCCESS,
} from '../config/contants';

export default (state = [], action) => {
  const {type, partners, partner, id} = action;
  switch (type) {
    case FETCH_PARTNERS_SUCCESS:
      return partners;
    case ADD_PARTNER_SUCCESS:
      return [
        ...state,
        partner,
      ];
    case DELETE_PARTNER_SUCCESS:
      return state.filter(item => item.id !== id);
    case UPDATE_PARTNER_SUCCESS:
      return state.map(item => {
        if (item.id === partner.id)
          return partner;

        return item;
      });
    default:
      return state;
  }
}