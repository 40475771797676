import React, {PureComponent} from 'react';
import '../style/component/partner.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import Loading from './loading.component';
import EditableText from './editable.text.component';

//TODO integration with Flow
class Partner extends PureComponent {

  constructor(props) {
    super(props);
    const {partner: {description='', contactLink='', downloadURL=''}} = props;

    this.state = {
      file: null,
      description,
      contactLink,
      downloadURL,
      newDownloadURL: '',
      showActionBtn: false,
      isLoading: false,
      loadingText: '',
    };
  }

  _onChangeInput = (event) => {
    const file = event.currentTarget.files[0];

    if (!file)
      return;

    console.warn(file);
    const reader = new FileReader();
    reader.onload = () => {
      const newDownloadURL = reader.result;
      this.setState({
        file,
        newDownloadURL,
      });
    };

    reader.readAsDataURL(file);
  };

  _onMouseEnter = () => this.setState({showActionBtn: true});

  _onMouseLeave = () => this.setState({showActionBtn: false});

  _deletePartner = (id, downloadURL) => {
    this.setState({isLoading: true, loadingText: 'Deletando parceiro...'}, async () => {
      await this.props.deletePartner(id, downloadURL);
      this.setState({isLoading: false, loadingText: ''});
    });
  };

  _uploadFile = () => {
    const {file, downloadURL} = this.state;
    const {partner: {id}} = this.props;

    if (!file) {
      this._updatePartner(id);
    } else {
      const metadata = {
        contentType: 'image/jpeg'
      };

      console.warn({downloadURL});

      this.setState(
      {isLoading: true, loadingText: 'Upload de arquivo...'},
      () => {
        this.props.uploadFile(
          id,
          file,
          metadata,
          downloadURL,
          this._uploadFileCompleted,
          () => this.setState({
            isLoading: false,
            loadingText: '',
            file: null,
            downloadURL: '',
            newDownloadURL: '',
          }),
        );
      });
    }
  };

  _uploadFileCompleted = (downloadURL) => {
    const {partner: {id}} = this.props;
    this.setState(
      {downloadURL},
      () => this._updatePartner(id)
    );
  };

  _updatePartner = (id) => {
    const {description, contactLink, downloadURL} = this.state;

    this.setState({isLoading: true, loadingText: 'Atualizando parceiro...'}, async () => {
      await this.props.updatePartner(id, {description, contactLink, downloadURL});
      this.setState({isLoading: false, loadingText: '', newDownloadURL: '', downloadURL});
    });
  };

  _closeEdition = () => {
    const {partner: {id, ...partner}} = this.props;
    this.setState({...partner, newDownloadURL: ''});
  };

  _openLink = (link) => (!link && link === '') || window.open(`https://${link.replace('https://', '').replace('http://', '')}`);

  render() {
    const {description, contactLink, downloadURL, newDownloadURL, showActionBtn, isLoading, loadingText} = this.state;
    const {partner: {id, ...partner}, hasPermission} = this.props;
    const existDownloadURL = newDownloadURL !== '' || downloadURL !== '';
    const showDownloadURL = newDownloadURL !== '' ? newDownloadURL : downloadURL;
    const isDescriptionEdited = partner.description !== description;
    const isContactLinkEdited = partner.contactLink !== contactLink;
    const isDownloadURLEdited = partner.downloadURL !== showDownloadURL;
    const isSaveEnabled = isDescriptionEdited || isContactLinkEdited || isDownloadURLEdited;

    return (
      <div
        className="flex column content-between align-stretch partner-container relative"
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
      >
        <div className='flex column align-stretch'>
          <span className='absolute-top-left flex row' style={{top: -2, right: -2}}>
            {isSaveEnabled &&
              <>
                <Icon
                  onClick={this._closeEdition}
                  fontFamily='AntDesign'
                  name='close'
                  color='#000000'
                  size={20}
                  className='icon-btn'
                />
                <Icon
                  fontFamily='Ionicons'
                  name='ios-save'
                  color='#000000'
                  size={23}
                  className='icon-btn'
                  onClick={() => this._uploadFile()}
                />
              </>
            }
            {showActionBtn && hasPermission &&
              <Icon
                fontFamily={'FontAwesome'}
                name={'trash-o'}
                color='#000000'
                size={23}
                className='icon-btn'
                onClick={() => this._deletePartner(id, downloadURL)}
              />
            }
          </span>
          <EditableText placeholder='Nome da Empresa' iconColor={'#000000'} hasPermission={hasPermission} save={(description) => this.setState({description})} text={description} classNames={'font-16 text-left-important partner-input-title'}/>
          {hasPermission ?
            <>
              <label
                className='flex content-center align-center partner-img-unavailable'
                htmlFor={`partner-file-upload-${id}`}
              >
                {existDownloadURL ?
                  <img className='partner-img' src={showDownloadURL} alt='IMAGEM'/>
                  :
                  <span>IMAGEM</span>
                }
              </label>
              <input
                id={`partner-file-upload-${id}`}
                onChange={this._onChangeInput}
                type='file'
                accept='image/*'
                style={{display: 'none'}}
              />
            </>
            :
            <img className='partner-img' src={downloadURL} alt='IMAGEM'/>
          }
        </div>
        <EditableText
          openLink={this._openLink}
          placeholder='Link'
          iconColor={'#000000'}
          hasPermission={hasPermission}
          save={(contactLink) => this.setState({contactLink})}
          text={contactLink}
          classNames={'font-16 text-left-important partner-input-link'}
        />
        <Loading isLoading={isLoading} text={loadingText}/>
      </div>
    );
  }
}

export default Partner;
