import axios from 'axios';
import {
  FETCH_OUR_SERVICES_SECOND,
  UPDATE_OUR_SERVICES_SECOND,
} from '../config/contants';

export const fetchSecondService = () => {
  return axios.get(`/secondService/`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: FETCH_OUR_SERVICES_SECOND,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const updateSecondService = (data) => {
  return axios.put(`/secondService/`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data,
  })
  .then(({status}) => {
    if (status === 200)
      return {
        type: UPDATE_OUR_SERVICES_SECOND,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};