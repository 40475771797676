import {
  FETCH_BANNER_SLIDE_TEXT,
  UPDATE_BANNER_SLIDE_TEXT,
} from '../config/contants';

export default (state = {
  civil_constr: 'CONSTRUÇÃO CIVIL',
  consult_invest: 'CONSULTORIA E INVESTIMENTO',
  financial_market: 'MERCADO FINANCEIRO',
  our_partner: 'SEJA NOSSO PARCEIRO',
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_BANNER_SLIDE_TEXT:
      return {
        ...state,
        ...data
      };
    case FETCH_BANNER_SLIDE_TEXT:
      return data;
    default:
      return state;
  }
}
