import React, {PureComponent} from 'react';
import '../style/component/contact.line.component.style.css';

//TODO integration with Flow
class ContactLine extends PureComponent {
  render() {
    const {text, onChange, placeholder, isTextArea, value} = this.props;

    return (
      <div className="flex column content-start align-stretch">
        <span className='contact-line-text font-15 weight-700'>{text}</span>
        {!isTextArea ?
          <input className='contact-line-input font-montserrat font-15 weight-400' onChange={onChange} placeholder={placeholder} value={value} />
          :
          <textarea className='contact-line-input font-montserrat font-15 weight-400 contact-line-textarea' onChange={onChange} placeholder={placeholder} value={value} />
        }
      </div>
    );
  }
}

export default ContactLine;
