import React, {PureComponent} from 'react';
import Icon from "../style/fonts/glyphmaps/icon.component";

class EditableText extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isEdit: false,
      showEditBtn: false,
      isLoading: false,
      value: null,
    };
  }

  _onMouseEnter = () => this.setState({showEditBtn: true});

  _onMouseLeave = () => this.setState({showEditBtn: false});

  _enableEdit = () => {
    const {onEnableEdit=() => {}} = this.props;
    this.setState({isEdit: true}, onEnableEdit);
  };

  _cancel = () => {
    const {onFinishEdit=() => {}} = this.props;
    this.setState({value: null, isEdit: false, showEditBtn: false}, onFinishEdit);
  };

  _save = () => {
    const {value} = this.state;
    const {text, save, onFinishEdit=() => {}, enableEmpty} = this.props;

    if (!save) {
      onFinishEdit();
      return;
    }

    if ((!enableEmpty && (!value && value === '')) || value === text) {
      alert('Realize uma alteração no texto para salvar.');
      return;
    }

    this.setState({value: null, isEdit: false, showEditBtn: false},  () => save(value || ''));
  };

  _onChangeValue = (event) => this.setState({value: event.currentTarget.value});

  _onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.props.save){
        this._cancel();
      } else {
        this._save();
      }
    }
  };

  _onFocusCapture = (event) => {
    const {value} = this.state;
    const {text} = this.props;
    event.currentTarget.value = '';
    event.currentTarget.value = value || text;
    this.setState({value: value || text});
  };

  render() {
    const {enableEmpty, placeholder, text, classNames, hasPermission, save, onChangeText, inputStyle={}, iconPosition={}, iconColor='black', isLoading, openLink} = this.props;
    const {isEdit, showEditBtn, value} = this.state;
    const isTextEmpty = (!value || value === '') && (!text || text === '');
    const isTextLink = !isTextEmpty && openLink;

    return (
      <div className={`edit-container`} onMouseEnter={this._onMouseEnter} onMouseLeave={this._onMouseLeave}>
        {!isEdit || !hasPermission ?
          <p onClick={isTextLink ? () => openLink(text) : null}
            className={`${classNames} edit-text ${isTextLink ? 'link-text' : ''}`}
            style={hasPermission ? inputStyle : {}}>
            {!enableEmpty && isTextEmpty ? placeholder : value || text}
          </p>
          :
          <textarea
            autoFocus={true}
            onFocusCapture={this._onFocusCapture}
            className={`${classNames} font-montserrat edit-input`}
            value={value || ''}
            placeholder={placeholder || ''}
            onChange={onChangeText || this._onChangeValue}
            onKeyDown={this._onKeyDown}
            style={hasPermission ? inputStyle : {}}
          />
        }
        <span className='absolute-top-right' style={iconPosition}>
          {isLoading ?
            <img src={require('../resource/loading.svg')} alt='carregando...' style={{width: 20, height: 20, backgroundColor: '#F0F0F0', borderRadius: 20}}/>
            :
            null
          }
          {isEdit && hasPermission ?
            <span>
              <Icon
                onClick={this._cancel}
                fontFamily='AntDesign'
                name='close'
                color={iconColor}
                size={20}
                className='icon-btn'
              />
              {!save ||
                <Icon
                  onClick={this._save}
                  fontFamily={'Ionicons'}
                  name={'ios-save'}
                  color={iconColor}
                  size={20}
                  className='icon-btn'
                />
              }
            </span>
            :
            null
          }
          {showEditBtn && !isEdit && !isLoading && hasPermission ?
            <Icon
              onClick={this._enableEdit}
              fontFamily={'FontAwesome'}
              name={'edit'}
              color={iconColor}
              size={20}
              className='icon-btn'
            />
            :
            null
          }
        </span>
      </div>
    )
  }
}

export default EditableText;
