import {
  FETCH_PARTNERS_SUCCESS,
  ADD_PARTNER_SUCCESS,
  UPDATE_PARTNER_SUCCESS,
  DELETE_PARTNER_SUCCESS,
} from '../config/contants';
import axios from 'axios';

export const getPartners = () => {
  return axios.get(`/partners`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: FETCH_PARTNERS_SUCCESS,
        partners: data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const addPartner = () => {
  return axios.post(`/partners`)
  .then(({data: {partner}, status}) => {
    if (status === 200)
      return {
        type: ADD_PARTNER_SUCCESS,
        partner,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const deletePartner = (id) => {
  console.warn({id});
  return axios.delete(`/partners/${id}`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: DELETE_PARTNER_SUCCESS,
        id,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const updatePartner = (id, partner) => {
  return axios.put(`/partners/${id}`, {partner})
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: UPDATE_PARTNER_SUCCESS,
        partner: {
          id,
          ...partner
        },
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};
