import React, {PureComponent} from 'react';
import '../style/component/invest.section.item.component.style.css';

class InvestSectionItem extends PureComponent {

  render() {
    const {source, data: {price, percent, date}, noFormat, imgStyle} = this.props;
    const formattedPrice =  price ? (noFormat ? price : `R$ ${price.replace('.', ',')}`) : '--,--';

    return (
      <div className="invest-section-item flex column content-between align-center">
        <div style={{height: 90}}>
          <img style={imgStyle} className='invest-section-item-image' src={source} alt='Erro ao carregar.'/>
        </div>
        <div className='flex column align-center'>
          <div className='flex row invest-section-item-row content-between'>
            <span className='invest-section-item-label'>Preço:</span>
            <span className='invest-section-item-text'>{formattedPrice}</span>
          </div>
          <div className='flex row invest-section-item-row content-between'>
            <span className='invest-section-item-label'>Cotação:</span>
            <span className='invest-section-item-text'>{percent ? percent.replace('.', ',') : '-,--'}%</span>
          </div>
          <div className='flex row invest-section-item-row content-between'>
            <span className='invest-section-item-label'>Data:</span>
            <span className='invest-section-item-text'>{date ? date : '--/--/----'}</span>
          </div>
        </div>
        {/*<div className='invest-section-item-button font-14 weight-700 flex content-center align-center'>INVESTIR</div>*/}
      </div>
    );
  }
}

export default InvestSectionItem;
