import {
  FETCH_FOOTER_CONTACT_TEXT,
  UPDATE_FOOTER_CONTACT_TEXT,
} from '../config/contants';

export default (state = {
  email: 'contato@aaempreendimentos.com.br',
  mobile_phone: '15 9 9999-9999',
  phone: '15 9 9999-9999',
}, action) => {
  const {type, data} = action;
  switch (type) {
    case UPDATE_FOOTER_CONTACT_TEXT:
      return {
        ...state,
        ...data
      };
    case FETCH_FOOTER_CONTACT_TEXT:
      return data;
    default:
      return state;
  }
}
