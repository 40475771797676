import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import '../style/component/loading.component.style.css';

//TODO integration with Flow
class Loading extends PureComponent {
  render() {
    const {isLoading, text='Carregando...'} = this.props;

    if (!isLoading)
      return null;

    return (
      <div className="full-absolute flex column content-center align-center loading-component">
        <img className='loading-image' src={require('../resource/loading.svg')} alt='carregando...'/>
        <span>{text}</span>
      </div>
    );
  }
}

const mapStateToProps = ({isLoadingVisible}) => {
  return {
    isLoadingVisible,
  };
};

export default connect(mapStateToProps, null)(Loading);
