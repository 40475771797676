import axios from 'axios';
import {
  FETCH_BANNER_SLIDE_TEXT,
  UPDATE_BANNER_SLIDE_TEXT,
} from '../config/contants';

export const fetchBannerTextList = () => {
  return axios.get(`/bannerTextList/`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: FETCH_BANNER_SLIDE_TEXT,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const updateBannerTextList = (data) => {
  return axios.put(`/bannerTextList/`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data,
  })
  .then(({status}) => {
    if (status === 200)
      return {
        type: UPDATE_BANNER_SLIDE_TEXT,
        data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};
