import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {
  applyMiddleware,
  createStore,
} from 'redux';
import reducers from './reducer';
import {default as promise} from 'redux-promise';
import './index.css';
import App from './app';
import * as serviceWorker from './serviceWorker';
import './style/fonts/fonts.css';

const store = createStore(reducers, undefined, applyMiddleware(promise));

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
