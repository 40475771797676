import React, {PureComponent} from 'react';
import '../style/component/contact.component.style.css';
import ContactLine from '../component/contact.line.component';
import {connect} from 'react-redux';
import {sendEmail} from '../action/email.action';

class Contact extends PureComponent {

  state = {
    name: '',
    email: '',
    text: '',
  };

  _sendEmail = async () => {
    const {name, email, text} = this.state;

    if (name === '') {
      alert('Por favor insira seu nome para enviar o email.');
      return;
    }

    if (email === '') {
      alert('Por favor insira seu email para entrarmos em contato.');
      return;
    }

    if (text === '') {
      alert('Por favor insira sua mensagem, assim entedemos melhor sua necessidade.');
      return;
    }
    const response = await this.props.sendEmail({name, email, text});
    console.warn({response});
    this.setState({name: '', email: '', text: ''});
    alert('Obrigado por nos enviar essa mensagem, entramos em contato o mais rápido possível!');
  };

  _onChangeName = (event) => this.setState({name: event.currentTarget.value});

  _onChangeEmail = (event) => this.setState({email: event.currentTarget.value});

  _onChangeText = (event) => this.setState({text: event.currentTarget.value});

  render() {
    const {name, email, text} = this.state;

    return (
      <div id="contact-section" className="flex column">
        <div className='flex column align-center flex-wrap contact-section-content'>
          <h2 className='contact-title font-30 weight-700'>CONTATO</h2>
          <div className='contact-content flex column'>
            <ContactLine text='NOME:' placeholder='Inserir nome' value={name} onChange={this._onChangeName}/>
            <ContactLine text='EMAIL:' placeholder='Inserir email' value={email} onChange={this._onChangeEmail}/>
            <ContactLine text='MENSAGEM:' placeholder='Inseria sua mensagem' isTextArea={true} value={text} onChange={this._onChangeText}/>
            <div className='send-btn flex align-center content-center' onClick={this._sendEmail}>
              <p className='font-16 weight-700'>ENVIAR</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps() {
  return {
    sendEmail: (data) => sendEmail(data),
  }
}

export default connect(null, mapDispatchToProps)(Contact);
