import axios from 'axios';
// LOCAL
// axios.defaults.baseURL = 'http://localhost:5000/aaempreendimentos-3fcf3/us-central1';
//PROD
// axios.defaults.baseURL = 'https://us-central1-aaempreendimentos-3fcf3.cloudfunctions.net';
axios.defaults.baseURL = 'https://us-central1-aaempreendimentos-eb0ed.cloudfunctions.net';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

//Alpha Vantage API KEY
export const ALPHA_VANTAGE_KEY= 'IEWNHP97Z86AWV48';

export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';

export const FETCH_SERVICES = 'FETCH_SERVICES';
export const FETCH_SERVICES_ERROR = 'FETCH_SERVICES_ERROR';
export const ADD_SERVICE = 'ADD_SERVICE';
export const ADD_SERVICE_ERROR = 'ADD_SERVICE_ERROR';

export const FETCH_BANNER_SLIDE_TEXT = 'FETCH_BANNER_SLIDE_TEXT';
export const UPDATE_BANNER_SLIDE_TEXT = 'UPDATE_BANNER_SLIDE_TEXT';

export const FETCH_BANNER_IMAGE = 'FETCH_BANNER_IMAGE';
export const UPDATE_BANNER_IMAGE = 'UPDATE_BANNER_IMAGE';

export const FETCH_FOOTER_CONTACT_TEXT = 'FETCH_FOOTER_CONTACT_TEXT';
export const UPDATE_FOOTER_CONTACT_TEXT = 'UPDATE_FOOTER_CONTACT_TEXT';

export const FETCH_ABOUT_US = 'FETCH_ABOUT_US';
export const UPDATE_ABOUT_US = 'UPDATE_ABOUT_US';

export const FETCH_COMPANY_PAGES = 'FETCH_COMPANY_PAGES';
export const ADD_COMPANY_PAGE_SUCCESS = 'ADD_COMPANY_PAGE_SUCCESS';
export const DELETE_COMPANY_PAGE_SUCCESS = 'DELETE_COMPANY_PAGE_SUCCESS';
export const ADD_COMPANY_SUCCESS  = 'ADD_COMPANY_SUCCESS';
export const DELETE_COMPANY_SUCCESS  = 'DELETE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_SUCCESS  = 'UPDATE_COMPANY_SUCCESS';
export const ADD_COMPANY_ERROR  = 'ADD_COMPANY_ERROR';
export const SET_COMPANY  = 'SET_COMPANY';

export const FETCH_PARTNERS_SUCCESS  = 'FETCH_PARTNERS_SUCCESS';
export const ADD_PARTNER_SUCCESS  = 'ADD_PARTNER_SUCCESS';
export const DELETE_PARTNER_SUCCESS  = 'DELETE_PARTNER_SUCCESS';
export const UPDATE_PARTNER_SUCCESS  = 'UPDATE_PARTNER_SUCCESS';

export const FETCH_FOOTER_ICONS = 'FETCH_FOOTER_ICONS';
export const UPDATE_FOOTER_ICONS = 'UPDATE_FOOTER_ICONS';

export const FETCH_OUR_SERVICES = 'FETCH_OUR_SERVICES';
export const UPDATE_OUR_SERVICES = 'UPDATE_OUR_SERVICES';
export const FETCH_OUR_SERVICES_FIRST = 'FETCH_OUR_SERVICES_FIRST';
export const UPDATE_OUR_SERVICES_FIRST = 'UPDATE_OUR_SERVICES_FIRST';
export const FETCH_OUR_SERVICES_SECOND = 'FETCH_OUR_SERVICES_SECOND';
export const UPDATE_OUR_SERVICES_SECOND = 'UPDATE_OUR_SERVICES_SECOND';
export const FETCH_OUR_SERVICES_THIRD = 'FETCH_OUR_SERVICES_THIRD';
export const UPDATE_OUR_SERVICES_THIRD = 'UPDATE_OUR_SERVICES_THIRD';

export const TOGGLE_SERVICE = 'TOGGLE_SERVICE';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';


