import React, {PureComponent} from 'react';
import * as glyphMap from './';

//TODO integration with Flow
class Icon extends PureComponent<> {

  render() {
    const {fontFamily, name, size, color, style, onClick, className, title} = this.props;

    const glyphItem = glyphMap[fontFamily];

    let glyph = name ? glyphItem[name] || '?' : '';
    if (typeof glyph === 'number') {
      glyph = String.fromCharCode(glyph);
    }

    let fontStyle = {
      fontSize: size ? size : 30,
      color: color ? color : '#000',
      fontFamily: fontFamily,
      fontWeight: 'normal',
      fontStyle: 'normal',
      ...style,
    };

    return (
      <i
        style={fontStyle}
        onClick={onClick}
        className={className}
        title={title}
      >
        {glyph}
      </i>
    );
  }
}

export default Icon;
