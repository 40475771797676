import React, {PureComponent} from 'react';
import '../style/component/company.component.style.css';
import Icon from '../style/fonts/glyphmaps/icon.component';
import Loading from './loading.component';
import EditableText from './editable.text.component';

//TODO integration with Flow
class Company extends PureComponent {

  constructor(props) {
    super(props);
    const {company: {name='', details='', contactLink='', downloadURL=''}} = props;

    this.state = {
      file: null,
      name,
      details,
      contactLink,
      downloadURL,
      newDownloadURL: '',
      showActionBtn: false,
      isLoading: false,
      loadingText: '',
    };
  }

  _onChangeInput = (event) => {
    const file = event.currentTarget.files[0];

    if (!file)
      return;

    console.warn(file);
    const reader = new FileReader();
    reader.onload = () => {
      const newDownloadURL = reader.result;
      this.setState({
        file,
        newDownloadURL,
      });
    };

    reader.readAsDataURL(file);
  };

  _onMouseEnter = () => this.setState({showActionBtn: true});

  _onMouseLeave = () => this.setState({showActionBtn: false});

  _deleteCompany = (id, downloadURL) => {
    this.setState({isLoading: true, loadingText: 'Deletando empresa...'}, async () => {
      await this.props.deleteCompany(id, downloadURL);
      this.setState({isLoading: false, loadingText: ''});
    });
  };

  _uploadFile = () => {
    const {file, downloadURL} = this.state;
    const {company: {id}} = this.props;

    if (!file) {
      this._updateCompany(id);
    } else {
      const metadata = {
        contentType: 'image/jpeg'
      };

      console.warn({downloadURL});

      this.setState(
      {isLoading: true, loadingText: 'Upload de arquivo...'},
      () => {
        this.props.uploadFile(
          id,
          file,
          metadata,
          downloadURL,
          this._uploadFileCompleted,
          () => this.setState({
            isLoading: false,
            loadingText: '',
            file: null,
            downloadURL: '',
            newDownloadURL: '',
          }),
        );
      });
    }
  };

  _uploadFileCompleted = (downloadURL) => {
    const {company: {id}} = this.props;
    this.setState(
      {downloadURL},
      () => this._updateCompany(id)
    );
  };

  _updateCompany = (id) => {
    const {name, details, contactLink, downloadURL} = this.state;

    this.setState({isLoading: true, loadingText: 'Atualizando empresa...'}, async () => {
      await this.props.updateCompany(id, {name, details, contactLink, downloadURL});
      this.setState({isLoading: false, loadingText: '', newDownloadURL: '', downloadURL});
    });
  };

  _closeEdition = () => {
    const {company: {id, ...company}} = this.props;
    this.setState({...company, newDownloadURL: ''});
  };

  _openLink = (link) => (!link && link === '') || window.open(`https://${link.replace('https://', '').replace('http://', '')}`);

  render() {
    const {name, details, contactLink, downloadURL, newDownloadURL, showActionBtn, isLoading, loadingText} = this.state;
    const {company: {id, ...company}, hasPermission} = this.props;
    const existDownloadURL = newDownloadURL !== '' || downloadURL !== '';
    const showDownloadURL = newDownloadURL !== '' ? newDownloadURL : downloadURL;
    const isNameEdited = company.name !== name;
    const isDetailsEdited = company.details !== details;
    const isContactLinkEdited = company.contactLink !== contactLink;
    const isDownloadURLEdited = company.downloadURL !== showDownloadURL;
    const isSaveEnabled = isNameEdited || isDetailsEdited || isContactLinkEdited || isDownloadURLEdited;

    return (
      <div
        className="flex column content-between align-stretch company-container relative"
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
      >
        <span className='absolute-top-left flex row' style={{top: -2, left: -2}}>
          {isSaveEnabled &&
            <>
              <Icon
                onClick={this._closeEdition}
                fontFamily='AntDesign'
                name='close'
                color='#FFFFFF'
                size={20}
                className='icon-btn'
              />
              <Icon
                fontFamily='Ionicons'
                name='ios-save'
                color='#FFFFFF'
                size={23}
                className='icon-btn'
                style={{top: 0, right: 0}}
                onClick={() => this._uploadFile()}
              />
            </>
          }
          {showActionBtn && hasPermission &&
            <Icon
              fontFamily={'FontAwesome'}
              name={'trash-o'}
              color='#FFFFFF'
              size={23}
              className=' icon-btn'
              onClick={() => this._deleteCompany(id, downloadURL)}
            />
          }
        </span>
        <span className='flex column' style={{backgroundColor: '#000000'}}>
          <EditableText placeholder='TITULO' iconColor={'#FFFFFF'} hasPermission={hasPermission} save={(name) => this.setState({name})} text={name} classNames={'font-16 text-left-important company-input-title'}/>
          {hasPermission ?
            <>
              <label
                className='flex content-center align-center company-img-unavailable'
                htmlFor={`company-file-upload-${id}`}
              >
                {existDownloadURL ?
                  <img className='company-img' src={showDownloadURL} alt='imagem'/>
                  :
                  <span>IMAGEM</span>
                }
              </label>
              <input
                id={`company-file-upload-${id}`}
                onChange={this._onChangeInput}
                type='file'
                accept='image/*'
                style={{display: 'none'}}
              />
            </>
            :
            <img className='company-img' src={downloadURL} alt='imagem'/>
          }
        </span>
        <div>
          <EditableText inputStyle={{padding: '0 10px'}} placeholder='Descrição' hasPermission={hasPermission} save={(details) => this.setState({details})} text={details} classNames={'font-16 text-left-important'}/>
          <EditableText enableEmpty={true} openLink={this._openLink} inputStyle={{padding: '0 10px'}} placeholder='Link' hasPermission={hasPermission} save={(contactLink) => this.setState({contactLink})} text={contactLink} classNames={'font-16 text-left-important'}/>
        </div>
        <Loading isLoading={isLoading} text={loadingText}/>
      </div>
    );
  }
}

export default Company;
