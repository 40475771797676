import {
  TOGGLE_SERVICE
} from '../config/contants';

export default (state = false, {type}) => {
  switch (type) {
    case TOGGLE_SERVICE:
      return !state;
    default:
        return state;
  }
}