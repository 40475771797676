import {
  SET_COMPANY,
  ADD_COMPANY_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_SUCCESS,
} from '../config/contants';
import axios from 'axios';

export const getCompanies = (id) => {
  return axios.get(`/company_pages/${id}`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: SET_COMPANY,
        companies: data,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const addCompany = (id) => {
  return axios.post(`/company_pages/${id}`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: ADD_COMPANY_SUCCESS,
        company: data.company,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const deleteCompany = (id, companyId) => {
  console.warn({id, companyId});
  return axios.delete(`/company_pages/${id}/${companyId}`)
  .then(({data, status}) => {
    if (status === 200)
      return {
        type: DELETE_COMPANY_SUCCESS,
        companyId,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};

export const updateCompany = (id, companyId, company) => {
  return axios.put(`/company_pages/${id}/${companyId}`, {company})
  .then(({data: {company, message}, status}) => {
    if (status === 200)
      return {
        type: UPDATE_COMPANY_SUCCESS,
        company,
      };

    throw new Error();
  })
  .catch(error => {
    console.log('Por favor entre em contato com o suporte.', error);
    throw error;
  });
};
